import React, { useState } from 'react';
import logoImage from 'media/logo.png';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import './Login.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    // Get tenant, use to create API endpoint
    const tenant = window.location.hostname.split(".")[0]
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const baseUrl = apiUrl.replace("*", tenant)

    const loginApiEndpoint = `${baseUrl}/api/v2/login/`;

    const handleLogin = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const response = await fetch(loginApiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
                credentials: 'include'
            });

            const data = await response.json();
            if (response.ok) {
                console.log('Login successful:', data);
                localStorage.setItem('username', username);
                redirectDashboard(data.home_page);
            } else {
                throw new Error("Username or Password incorrect! Try again!");
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const redirectDashboard = (homePage) => {
        if (homePage == "/admin/") {
            homePage = baseUrl + homePage;
        }
        window.location.href = homePage;
    };

    return (
        <div className="login-container">
            <form onSubmit={handleLogin} style={{ width: '100%', maxWidth: '420px' }}>
            <img src={logoImage} alt="RecessD Logo" className="logo" />
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    margin="normal"
                    required
                />
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    margin="normal"
                    required
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isLoading}
                    sx={{ mt: 3, mb: 2, height: 48, padding: '10px 0' }}
                >
                    {isLoading ? <CircularProgress size={50} /> : "Sign In"}
                </Button>
                {error}
            </form>
        </div>
    );
};

export default Login;
