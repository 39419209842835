const tenant = window.location.hostname.split('.')[0];
const apiBaseUrl = process.env.REACT_APP_API_ENDPOINT.replace('*', tenant);

// Define and export endpoints
const appEndpoints = {
  login: `/app/v3/login/`,
  controlPanel: `${apiBaseUrl}/zone_management/`,
  dashboard: `/app/v3/dashboard/`,
  reporting: `/app/v3/reporting/`,
  userManual: `/app/v3/file-manager/`,
  flowConfiguration: '/app/v3/flows/',
  ruleEngine: `/app/v3/rule_engine/`,
};

const apiEndpoints = {
  logout: `${apiBaseUrl}/api/v2/logout/`,
  devices: `${apiBaseUrl}/api/devices/`,
  deviceConfig: `${apiBaseUrl}/api/v2/device/config/`,
  deviceParameters: `${apiBaseUrl}/api/device_state_node_b/`,
  legacySensors: `${apiBaseUrl}/api/sensors/`,
  reportSettingEndpoint: `${apiBaseUrl}/api/v2/report/setting/`,
  legacyReportSettingEndpoint: `${apiBaseUrl}/api/sensor_report_setting/`,
  flows: `${apiBaseUrl}/api/v2/flow/`,
  tenantManagementEndpoint: `${apiBaseUrl}/api/v2/tenant/`,
};

export { appEndpoints, apiEndpoints };
