import './MenuItems.css';

import Logout from '../logout/Logout';

import { appEndpoints } from '../../config/Endpoints';

const MenuItems = () => {
    const menuItemsList = [
        {
            title: 'Control Panel',
            url: appEndpoints.controlPanel,
        },
        {
            title: 'Dashboard',
            url: appEndpoints.dashboard,
        },
        {
            title: 'User Manual',
            url: appEndpoints.userManual,
        },
        {
            title: 'Reporting',
            url: appEndpoints.reporting,
        },
        {
            title: 'Flows',
            url: appEndpoints.flowConfiguration,
        },
        {
            title: 'Rule Engine',
            url: appEndpoints.ruleEngine,
        },

    ]; return (
        <ul className="menu-items-list">
            {menuItemsList.map((menuItem, index) => (
                <li key={index}>
                    <a href={menuItem.url} className="link-button">{menuItem.title}</a>
                </li>
            ))}
            <li>
                <Logout />
            </li>
        </ul>
    );
};

export default MenuItems;
