import React, { useState, useEffect, useCallback } from 'react';
import $ from 'jquery';

import Switch from '@mui/material/Switch';
import { FiberManualRecord as FiberManualRecordIcon } from '@mui/icons-material';

import './NodeBDevice.css';

const NodeBDevice = ({ device_id, hex_address, device_name, board_type }) => {
    const [data, setData] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);
    const [loading, setLoading] = useState(true);

    // Get tenant, use to create API endpoint
    const tenant = window.location.hostname.split(".")[0]
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const baseUrl = apiUrl.replace("*", tenant)

    const fetchData = useCallback(async () => {
        try {
            if (initialLoad) setLoading(true);
            let queryParams = new URLSearchParams({ device_hex_address: hex_address });
            const dataUrl = `${baseUrl}/api/v2/node_b_parameters_state?${queryParams.toString()}`;
            const response = await $.ajax({
                url: dataUrl,
                method: 'GET',
                xhrFields: {
                    withCredentials: true,
                },
            });
            setData(response);
            if (initialLoad) setInitialLoad(false); // After initial load, set to false
        } catch (error) {
            console.error('Error in fetching data:', error);
        } finally {
            setLoading(false);
            if (initialLoad) setLoading(false);
        }
    }, [hex_address, baseUrl, initialLoad]);

    useEffect(() => {
        fetchData(); // Fetch immediately on mount
        const interval = setInterval(fetchData, 15000); // Then every 15 seconds
        return () => clearInterval(interval); // Clear interval on component unmount
    }, [fetchData]);

    function renderParameter(label, value, type) {
        let valueElement;
        let typeElement;
        switch (type.substring(0, 1)) {
            case 'r':
                valueElement = <Switch checked={value === 1} onChange={() => { }} name="relaySwitch" color="primary" className='custom-switch' />;
                typeElement = <span className='text-light'> Relay </span>;
                break;
            case 't':
                valueElement = <span className="parameter-value">{value / 100}°F</span>;
                typeElement = <span className='text-light'> Temperature </span>;
                break;
            case 'd':
                valueElement = <FiberManualRecordIcon style={{ color: value ? 'green' : 'red', fontSize: '3rem', }} />;
                typeElement = <span className='text-light'> Digital Sensor </span>;
                break;
            case 'a':
                valueElement = <span className="parameter-value">{value / 10} V</span>;
                typeElement = <span className='text-light'> 0-10V Output </span>;
                break;
            default:
                valueElement = <span className="parameter-value">{value}</span>;
        }

        return (
            <div className="parameter-row">
                <div>
                    <span className="text-bold">{label}</span>
                    <div style={{ fontSize: '12px' }}>{typeElement}</div>
                </div>

                {valueElement}
            </div>
        );
    };

    function renderBoard(name, data) {
        return (
            <div className="node-b-board">
                <div className="widget-title">{name}</div>
                <div>
                    {
                    Object.entries(data).map(([key, entry], index) =>
                        renderParameter(entry.name, entry.value, key, `param-${index}-${entry.id || entry.name}`)
                    )
                }</div>
            </div>
        );
    };

    // Pass data attributes conditionally depending on board type
    if (board_type === undefined) {
        return (
            <div className="widget-container">
                {loading ? <div>Loading...</div> : data.zeroboard && renderBoard(device_name, data.zeroboard.sa)}
            </div>
        );
    }

    else if (board_type === 'zeroboard') {
        return (
            <div className="widget-container">
                {loading ? <div>Loading...</div> : data.zeroboard && renderBoard(device_name, data.zeroboard.sa)}
            </div>
        );
    }

    else if (board_type === 'relay8board') {
        return (
            <div className="widget-container">
                {loading ? <div>Loading...</div> : data.relay8board && renderBoard(device_name, data.relay8board.relay)}
            </div>
        );
    }

    else{
        return (
            <div className="widget-container">
                {loading ? <div>Loading...</div> : data.zeroboard && renderBoard(device_name, data.zeroboard.sa)}
            </div>
        );
    }
};

export default NodeBDevice;
