import { useState } from 'react';
import { appEndpoints } from '../config/Endpoints';

import Cookies from 'js-cookie';


const useDeleteItems = (endpoint, setRefetchIndex) => {
    const deleteItems = async (ids) => {
        try {
            const csrfToken = Cookies.get('csrftoken');
            const response = await fetch(endpoint, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify({ ids })
            });
            if (response.ok) {
                setRefetchIndex(prevIndex => prevIndex + 1);
            } else {
                throw new Error('Failed to delete items');
            }
        } catch (error) {
            throw new Error(error.message);
        }
    };

    return { deleteItems };
};

export default useDeleteItems;
