import { Outlet } from 'react-router-dom';

import NavBar from './NavBar';
import Footer from './Footer';
import Notification from '../general/Notification';
import Loading from '../general/Loading';
import { NotificationProvider, useNotification } from '../context/NotificationContext';
import { LoadingProvider, useLoading } from '../context/LoadingContext';

import './Layout.css'


const LayoutContent = () => {
    const { message, severity, showMessage } = useNotification();
    const { loading } = useLoading();

    return (
        <div>
            <NavBar />
            <div className='content-container'>
                <div className="main-content">
                    <Notification
                        message={message}
                        severity={severity}
                        onClose={() => showMessage(null)}
                    />
                    {loading && <Loading />}
                    <Outlet />
                </div>
            </div>
            <Footer />
        </div>
    );
};

const Layout = () => {
    return (
        <NotificationProvider>
            <LoadingProvider>
                <LayoutContent />
            </LoadingProvider>
        </NotificationProvider>
    );
};

export default Layout;
