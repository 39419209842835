import React from 'react';
import logoImage from 'media/logo.png';

import MenuItems from './MenuItems.js';

import './NavBar.css'

function NavBar() {
  return (
    <div className="navbar-static-top">

      <div className="navbar-column-left">
        <a href="/" className="navbar-brand" >
          <img src={logoImage} alt="Logo" className="nav-logo" />
        </a>
      </div>

      <div className="navbar-column-center">
      </div>

      <div className="navbar-column-right">
        <MenuItems />
      </div>

    </div>
  );
};

export default NavBar;
