// React and third-party libraries
import { useState } from 'react';
import Cookies from 'js-cookie';

// Configurations
import { apiEndpoints, appEndpoints } from '../config/Endpoints';
import {legacyParameters} from '../config/Parameters';

const useSaveReportSetting = () => {
    const [isReportRangeLoading, setIsReportRangeLoading] = useState(false);

    // Function to call the API
    const saveRange = async (deviceId, deviceType, parameter, reportSetting) => {
        setIsReportRangeLoading(true);

        let endpoint, requestData;
        if (deviceType === 'Sensor') {
            endpoint = `${apiEndpoints.reportSettingEndpoint}${deviceId}/`;
            requestData = JSON.stringify({"setting": reportSetting});
        } else {
            endpoint = `${apiEndpoints.legacyReportSettingEndpoint}${deviceId}/`;
            requestData = JSON.stringify({
                [`${parameter}_range_min`]: reportSetting[`${parameter}_range_min`],
                [`${parameter}_range_max`]: reportSetting[`${parameter}_range_max`]
            });
        }

        try {
            const csrfToken = Cookies.get('csrftoken');
            const response = await fetch(endpoint, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: requestData,
                credentials: 'include'
            });

            const responseData = await response.json();
            if (response.ok) {
                console.log('Range saved successfully:', responseData);
            } else {
                throw new Error(responseData.message || "Failed to save the range.");
            }
        } catch (error) {
            console.error("Save error: ", error);
        } finally {
            setIsReportRangeLoading(false);
        }
    };

    return { isReportRangeLoading, saveRange };
};

export default useSaveReportSetting;
