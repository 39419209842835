// React and third-party libraries
import React, { createContext, useState, useContext } from 'react';


const LoadingContext = createContext();


export const useLoading = () => {
    return useContext(LoadingContext);
};


export const LoadingProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);

    const showLoading = () => setLoading(true);
    const hideLoading = () => setLoading(false);

    return (
        <LoadingContext.Provider value={{ loading, showLoading, hideLoading }}>
            {children}
        </LoadingContext.Provider>
    );
};
