import React from 'react';
import { FormControl, MenuItem, TextField } from '@mui/material';
import { FLOW_TYPE_TO_MODES_MAPPING } from 'components/utils/Flows';

const FlowModeSelector = ({ selectedFlowModeKey, selectedFlowKey, onFlowModeChange, disabled }) => {
    return (
        <FormControl fullWidth>
            <TextField
                select
                label="Flow"
                value={selectedFlowModeKey}
                onChange={e => onFlowModeChange(e.target.value)}
                required
                disabled={disabled}
            >
                {selectedFlowKey !== "" && (
                    Object.entries(FLOW_TYPE_TO_MODES_MAPPING[selectedFlowKey]).map(([key, name]) => (
                        <MenuItem key={key} value={name}>
                            {name}
                        </MenuItem>
                    ))
                )}
            </TextField>
        </FormControl>
    );
};

export default FlowModeSelector;