import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, Chip, Box } from '@mui/material';

function MultiSelectDropdown({ label, itemsData }) {
  const [selectedItems, setSelectedItems] = useState([]);

  // Populate the initial state based on the itemsData
  useEffect(() => {
    if (itemsData) {
      const initialItems = itemsData.map(item => item.name);
      setSelectedItems(initialItems);
    }
  }, [itemsData]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedItems(
      // On autofill we get a stringified value, handle as array otherwise
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="multi-select-dropdown-label">label</InputLabel>
      <Select
        labelId="multi-select-dropdown-label"
        id="multi-select-dropdown"
        multiple
        value={selectedItems}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Select Items" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
      >
        {itemsData.map((item) => (
          <MenuItem
            key={item.id}
            value={item.name}  // using item.name as value for the sake of display and uniqueness
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultiSelectDropdown;
