import React from 'react';
import { FormControl, TextField, Checkbox, FormControlLabel } from '@mui/material';

const NumericalParameterSelector = ({
    initialDisplayValue,
    parameterDisplayName,
    onParameterChange,
    parameterType,
    parameterID
}) => {
    const handleChange = (event) => {
        const value = parameterType === 'float' ? parseFloat(event.target.value) : event.target.value;
        onParameterChange(value, parameterID);
    };

    return (
        <FormControl fullWidth>
            {parameterType === 'boolean' ? (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={initialDisplayValue}
                            onChange={(event) => onParameterChange(event.target.checked, parameterID)}
                        />
                    }
                    label={parameterDisplayName}
                />
            ) : (
                <TextField
                    id={parameterID}
                    value={initialDisplayValue}
                    label={parameterDisplayName}
                    type={parameterType === 'float' ? 'number' : 'text'}
                    maxWidth='lg'
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={handleChange}
                    className='parameter-input'
                    sx={{ minWidth: '100%' }}
                />
            )}
        </FormControl>
    );
};

export default NumericalParameterSelector;
