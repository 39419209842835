import React from 'react';
import { FormControl, MenuItem, TextField } from '@mui/material';
import { FLOW_TYPE_TO_NAME_MAPPING } from 'components/utils/Flows';

const FlowSelector = ({ selectedFlowKey, onFlowChange, disabled }) => {
    return (
        <FormControl fullWidth>
            <TextField
                select
                label="Flow"
                value={selectedFlowKey}
                onChange={e => onFlowChange(e.target.value)}
                required
                disabled={disabled}
            >
                {Object.entries(FLOW_TYPE_TO_NAME_MAPPING).map(([key, name]) => (
                    <MenuItem key={key} value={key}>
                        {name}
                    </MenuItem>
                ))}
            </TextField>
        </FormControl>
    );
};

export default FlowSelector;
