// React and third-party libraries
import React from 'react';
import { FormControl, TextField } from '@mui/material';

const RangeSelector = ({ label, value, onRangeChange}) => {
    return (
        <FormControl fullWidth>
            <TextField
                label={label}
                type="number"
                value={value}
                onChange={e => onRangeChange(e.target.value)}
                required
            />
        </FormControl>
    );
};

export default RangeSelector;
