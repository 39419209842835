import { BrowserRouter, Routes, Route, } from 'react-router-dom';

import Layout from 'components/layout/Layout'
import Login from 'components/routes/Login';
import Dashboard from 'components/routes/Dashboard';
import FileManager from 'components/routes/FileManager'
import Reporting from './components/routes/Reporting';
import FlowConfiguration from './components/routes/FlowConfiguration';
import TenantManagement from './components/routes/TenantManagement';

function App() {
  return (
    <BrowserRouter basename="/app/v3" >
      <Routes >
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<Layout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/file-manager" element={<FileManager />} />
          <Route path="/reporting/" element={<Reporting />} />
          <Route path="/flows/" element={<FlowConfiguration />} />
          <Route path="/tenant-management/" element={<TenantManagement />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;