import { useState } from 'react';
import { appEndpoints } from '../config/Endpoints';

import Cookies from 'js-cookie';


const useEditItem = (endpoint, setRefetchIndex, setError) => {
    const editItem = async (id, data) => {
        try {
            const csrfToken = Cookies.get('csrftoken');
            const response = await fetch(`${endpoint}${id}/`, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify(data)
            });
            if (response.ok) {
                setRefetchIndex(prevIndex => prevIndex + 1);
                return await response.json();
            } else {
                const errorMessage = await response.json()
                setError(errorMessage);
                throw new Error('Failed to delete items');
            }
        } catch (error) {
            throw new Error(error.message);
        }
    };

    return { editItem };
};

export default useEditItem;
