import { useState, useEffect } from 'react';
import { appEndpoints } from '../config/Endpoints';

const useFetchItems = (endpoint, page, rowsPerPage, refetchIndex) => {
    const [data, setData] = useState([]);
    const [count, setCount] = useState();

    useEffect(() => {
        const fetchEndpoint = `${endpoint}?page=${page + 1}&page_size=${rowsPerPage}`

        fetch(fetchEndpoint, {
            method: 'GET',
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                if (response.status === 401 || response.status === 403) {
                    window.location = appEndpoints.login;
                }
                throw new Error(`Failed to fetch data from ${endpoint}`);
            }
        }).then(data => {
            setData(data.data);
            setCount(data.total);
        }).catch(error => {
            throw new Error(error.message);
        });

    }, [endpoint, page, rowsPerPage, refetchIndex]);

    return { data, count };
};

export default useFetchItems;
