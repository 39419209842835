import { useState } from 'react';
import { appEndpoints } from '../config/Endpoints';

import Cookies from 'js-cookie';


const useSaveItem = (endpoint, setRefetchIndex, setError) => {
    const saveItem = async (data) => {
        try {
            const csrfToken = Cookies.get('csrftoken');
            const response = await fetch(endpoint, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify(data)
            });
            if (response.ok) {
                setRefetchIndex(prevIndex => prevIndex + 1);
                return await response.json();
            } else {
                const errorMessage = await response.json()
                setError(errorMessage);
                throw new Error(JSON.stringify(errorMessage));
            }
        } catch (error) {
            throw new Error(error.message);
        }
    };

    return { saveItem };
};

export default useSaveItem;
