import React from 'react';

import './Footer.css'

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-bold-text">Copyright © RecessD Automation.</div>
      <div className="footer-text">All rights reserved.</div>
    </div>
  );
};

export default Footer;
