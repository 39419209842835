// React and third-party libraries
import React from 'react';
import { FormControl, MenuItem, TextField } from '@mui/material';

const ParameterSelector = ({ parameters, selectedParameterId, onParameterChange }) => {
    return (
        <FormControl fullWidth>
            <TextField
                select
                label="Parameter"
                value={selectedParameterId}
                onChange={e => onParameterChange(e.target.value)}
                required
            >
                {parameters.map(param => (
                    <MenuItem key={param.id} value={param.id}>
                        {param.name}
                    </MenuItem>
                ))}
            </TextField>
        </FormControl>
    );
};

export default ParameterSelector;
