import React from 'react';
import Cookies from 'js-cookie';

import {apiEndpoints, appEndpoints} from '../../config/Endpoints';

import './Logout.css'

const Logout = () => {
  const handleLogout = async () => {
    try {
      const csrfToken = Cookies.get('csrftoken');
      const response = await fetch(apiEndpoints.logout, {
        method: 'POST',
        headers: {
          'X-CSRFToken': csrfToken,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (response.ok) {
        window.location.href = appEndpoints.login;
      } else {
        console.error('Failed to log out');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <a onClick={handleLogout} className="link-button">
      Sign Out
    </a>
  );
};

export default Logout;
