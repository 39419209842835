// React and third-party libraries
import React from 'react';
import { FormControl, MenuItem, TextField } from '@mui/material';

const DeviceSelector = ({ devices, selectedDeviceKey, onDeviceChange, disabled = false }) => {
    return (
        <FormControl fullWidth>
            <TextField
                select
                label="Device"
                value={selectedDeviceKey}
                onChange={e => onDeviceChange(e.target.value)}
                disabled={disabled}
                required
            >
                {devices.map(device => (
                    <MenuItem key={`${device.id}:${device.device_type}`} value={`${device.id}:${device.device_type}`}>
                        {device.device_name}
                    </MenuItem>
                ))}
            </TextField>
        </FormControl>
    );
};

export default DeviceSelector;
