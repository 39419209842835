import React, { useState, useEffect, useCallback, useRef } from 'react';
import $ from 'jquery'
import './ThermostatDevice.css'

const ThermostatDial = ({ device_id, device_name, minValue = 62, maxValue = 90, numTicks = 150 }) => {
    // Use ref to measure container size
    const containerRef = useRef(null);
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
    const [data, setData] = useState(null);

    // Get tenant, use to create API endpoint
    const tenant = window.location.hostname.split(".")[0]
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const baseUrl = apiUrl.replace("*", tenant)

    // Update container size on window resize
    useEffect(() => {
        const updateSize = () => {
            if (containerRef.current) {
                setContainerSize({
                    width: containerRef.current.offsetWidth,
                    height: containerRef.current.offsetHeight
                });
            }
        };
        window.addEventListener('resize', updateSize);
        updateSize(); // Initial size update
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    // Adjust diameter based on container size
    const diameter = Math.min(containerSize.width, containerSize.height);
    const targetTemperature = minValue;
    const hvacState = 'off'; // Possible states: off, heating, cooling

    // Calculate properties based on options
    const radius = diameter / 2;
    const tickDegrees = 300;
    const rangeValue = maxValue - minValue;
    const tickOuterRadius = diameter / 30;
    const tickInnerRadius = diameter / 8;
    const offsetDegrees = 180 - (360 - tickDegrees) / 2;

    // Rotate points for ticks
    const rotatePoint = (point, angle, origin) => {
        const radians = (angle * Math.PI) / 180;
        const x = point[0] - origin[0];
        const y = point[1] - origin[1];
        return [
            x * Math.cos(radians) - y * Math.sin(radians) + origin[0],
            x * Math.sin(radians) + y * Math.cos(radians) + origin[1],
        ];
    };

    // Create tick marks
    const createTicks = () => {
        const rs = parseFloat(data?.rs || minValue);
        const rt = parseFloat(data?.rt || minValue);
        const ticks = [];
        for (let i = 0; i < numTicks; i++) {
            const angle = (tickDegrees / numTicks) * i - offsetDegrees;
            const start = rotatePoint([radius, tickOuterRadius], angle, [radius, radius]);
            const end = rotatePoint([radius, tickInnerRadius], angle, [radius, radius]);
            // Determine if the tick should be highlighted based on rs and rt
            const value = minValue + (rangeValue / numTicks) * i;
            const stroke = value >= rs && value <= rt ? 'white' : 'gray';
            ticks.push(<line key={i} x1={start[0]} y1={start[1]} x2={end[0]} y2={end[1]} stroke={stroke} />);
        }
        return ticks;
    };

    // Function to fetch data
    const fetchData = useCallback(async () => {
        try {
            const dataUrl = `${baseUrl}/api/node/b/hvac/data/${device_id}`;
            const response = await $.ajax({
                url: dataUrl,
                method: 'GET',
                xhrFields: {
                    withCredentials: true
                },
            })
            response.ot = response.ot * 9 / 5 + 32
            response.rt = response.rt * 9 / 5 + 32
            setData(response)
        } catch (error) {
            console.error('Error in fetching data:', error);
        } finally {
        }
    }, [device_id, baseUrl]);

    // useEffect hook for fetching device data from the API
    useEffect(() => {
        fetchData()
        const intervalId = setInterval(fetchData, 15000); // Setup interval for refreshing every 15 seconds
        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, [fetchData]);

    // Function to calculate the position of the set temperature label
    const calculateLabelPosition = (temperature) => {
        const temperatureValue = parseFloat(temperature);
        // Ensure the temperature value is within the allowed range
        const safeTemperature = Math.min(Math.max(temperatureValue, minValue), maxValue);
        // Calculate the angle for the temperature
        const angle = ((safeTemperature - minValue) / (maxValue - minValue)) * tickDegrees - offsetDegrees;
        // Calculate the position based on the angle
        const radiusOffset = tickInnerRadius + 20; // Adjust this value to move the label closer to or further from the center
        const position = rotatePoint([radius, radiusOffset], angle, [radius, radius]);
        return position;
    };

    const setTemperatureLabel = () => {
        const temp = parseFloat(data?.rs || targetTemperature);
        const rs = parseFloat(data?.rs || minValue);
        // Decide the label's position based on the comparison between rt and rs
        const position = calculateLabelPosition(temp, temp > rs ? -30 : 30); // Adjust the offset to move outside the ticks
        return (
            <text
                x={position[0]}
                y={position[1]}
                textAnchor="middle"
                alignmentBaseline="middle" // Adjusts the alignment to the middle of the font
                style={{ fill: 'white', fontSize: '16px', fontFamily: 'Helvetica' }}
            >
                {temp.toFixed(0)}
            </text>
        );
    };

    const backgroundColor = () => {
        if (data?.heating_stage1 === 1 || data?.heating_stage2 === 1) return '#e36304';
        if (data?.cooling_stage1 === 1 || data?.cooling_stage2 === 1) return '#007af1';
        return 'black';
    };

    return (
        <fieldset className="widget-container" style={{ maxWidth: "100%", justifyContent: "center", verticalAlign: "top", height: "100%", overflow: "hidden", fontSize: "calc(1vw + 1vh + .5vmin)" }} ref={containerRef}>
            <div className="widget-title">{device_name || 'Thermostat'}</div>
            <svg width={diameter} height={diameter} viewBox={`0 0 ${diameter} ${diameter}`}>

                <circle cx={radius} cy={radius} r={radius} fill={backgroundColor()} />
                <g className="ticks">{createTicks()}</g>
                {setTemperatureLabel()}

                <text
                    x={radius}
                    y={radius + 20}
                    textAnchor="middle"
                    style={{ fill: 'white', fontSize: diameter / 5, fontFamily: 'Helvetica' }}
                >
                    {parseFloat(data?.rt || targetTemperature).toFixed(0)}
                </text>
                <text
                    x={radius}
                    y={radius + 40}
                    textAnchor="middle"
                    style={{ fill: 'white', fontSize: '16px', fontFamily: 'Helvetica' }}
                >
                    {data ? (data.heating_stage1 || data.heating_stage2 ? 'HEATING' : data.cooling_stage1 || data.cooling_stage2 ? 'COOLING' : 'OFF').toUpperCase() : hvacState.toUpperCase()}
                </text>
            </svg >
        </fieldset>
    );
};

export default ThermostatDial;
