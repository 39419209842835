// src/components/ConfirmationModal.js
import React from 'react';
import Button from '@mui/material/Button';
import './ConfirmationModal.css';

const ConfirmationModal = ({ headingText, subHeadingText, closeText, confirmText, isOpen, onClose, onConfirm }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="confirmation-modal-overlay">
            <div className="confirmation-modal-content">
                <h2>{headingText}</h2>
                <p>{subHeadingText}</p>
                <div className="confirmation-modal-buttons">
                    <Button variant="contained" onClick={onClose}>{closeText}</Button>
                    <Button variant="contained" color="error" onClick={onConfirm}>{confirmText}</Button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
