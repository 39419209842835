// React and third-party libraries
import { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import { CircularProgress, IconButton, Button, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ViewListIcon from '@mui/icons-material/ViewList';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Data hooks
import useSaveItem from '../../hooks/SaveItem';
import useEditItem from '../../hooks/EditItem';
import useFetchItems from '../../hooks/FetchItems';
import useDeleteItems from '../../hooks/DeleteItems';
import useDeleteItem from '../../hooks/DeleteItem';

// Context
import { useNotification } from '../context/NotificationContext';
import { useLoading } from '../context/LoadingContext';

// Styles
import './DataTable.css'


const DataTable = ({
    name,
    title,
    columns,
    endpoint,
    newData,
    isSubmit,
    setIsSubmit,
    handleOpen,
    handleClose,
    handleViewOpen,
    handleEditOpen,
    setError
}) => {
    const { showMessage } = useNotification();
    const { showLoading, hideLoading } = useLoading();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [refetchIndex, setRefetchIndex] = useState(0);
    const [idToEdit, setIdToEdit] = useState(0);
    const { data, count } = useFetchItems(endpoint, page, rowsPerPage, refetchIndex);
    const { deleteItems } = useDeleteItems(endpoint, setRefetchIndex);
    const { deleteItem } = useDeleteItem(endpoint, setRefetchIndex);
    const { saveItem } = useSaveItem(endpoint, setRefetchIndex, setError);
    const { editItem } = useEditItem(endpoint, setRefetchIndex, setError);
    const [selectedRows, setSelectedRows] = useState([]);

    const defaultColumns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display: false, // Hides the ID column from the table
            }
        },
        ...columns.map(col => ({
          name: col.name,
          label: col.label,
          options: {
            filter: col.filter || true,
            sort: col.sort || true,
            display: col.display || true,
            setCellProps: () => ({ style: { textAlign: 'left', paddingLeft: '42px' } }),
            setCellHeaderProps: () => ({ style: { textAlign: 'left', whiteSpace: 'nowrap' } }),
          }
        })),
        {
            name: "Actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellHeaderProps: () => ({ align: 'center' }),
                setCellProps: () => ({ align: 'center' }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Tooltip title="View">
                                <IconButton onClick={() => handleViewClick(tableMeta.rowData[0])} color="primary">
                                    <ViewListIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit">
                                <IconButton onClick={() => handleEditClick(tableMeta.rowData[0])} color="primary">
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton onClick={() => handleDeleteClick(tableMeta.rowData[0])} color="error">
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    );
                }
            }
        }
    ];

    const options = {
        search: false,
        print: false,
        viewColumns: false,
        filter: false,
        pagination: true,
        serverSide: true,
        count: count,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [5, 10, 15],
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    setPage(tableState.page);
                    break;
                case 'changeRowsPerPage':
                    setRowsPerPage(tableState.rowsPerPage);
                    break;
                default:
                    break;
            }
        },
        onRowsDelete: (rowsDeleted) => {
            const idsToDelete = rowsDeleted.data.map(d => data[d.dataIndex].id);

            deleteBulkData(idsToDelete);
        },
        onRowSelectionChange: (currentSelectedRows, allSelectedRows, rowsSelected) => {
            setSelectedRows(allSelectedRows);
        },
        customToolbar: () => (
            <Tooltip title={`Add New ${name}`}>
                <IconButton
                    color="primary"
                    aria-label={`Add New ${name}`}
                    onClick={addModule}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip>
        ),
        selectableRows: 'multiple',
    };

    useEffect(() => {
        const submitData = async () => {
            if (isSubmit) {
                showLoading();
                try {
                    if (idToEdit) {
                        await editItem(idToEdit, newData);
                        showMessage(`${name} updated successfully`, 'success');
                } else {
                        await saveItem(newData);
                        showMessage(`${name} created successfully`, 'success');
                    }
                    handleClose();
                } catch (error) {
                    // Handle error if needed
                }
                setIsSubmit(false);
                hideLoading();
            }
        };

        submitData();
    }, [isSubmit]);

    const addModule = () => {
        setIdToEdit(null);
        handleOpen();
    };

    const handleViewClick = (rowId) => {
        const dataToView = data.find(item => item.id === rowId);
        handleViewOpen(dataToView)
    };

    const handleEditClick = (rowId) => {
        setIdToEdit(rowId);
        const dataToEdit = data.find(item => item.id === rowId);
        handleEditOpen(dataToEdit);
    };

    const handleDeleteClick = (id) => {
        deleteData(id);
    };

    const deleteBulkData = async (idsToDelete) => {
        showLoading();
        await deleteItems(idsToDelete);
        showMessage(`${name}s deleted successfully`, 'error');
        hideLoading();
    };

    const deleteData = async (id) => {
        showLoading();
        await deleteItem(id);
        showMessage(`${name} deleted successfully`, 'error');
        hideLoading();
    };

    return (
        <MUIDataTable
            title={title}
            data={data}
            columns={defaultColumns}
            options={options}
        />
    );

};

export default DataTable;
