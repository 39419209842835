const getBaseURL = () => {
    const tenant = window.location.hostname.split(".")[0];
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    return apiUrl.replace("*", tenant);
};

const getFileURL = () => {
    // TODO: We need to see how we have base dir, may be through env
    // or we use presigned url, then no need to have bucket env as well
    const bucket = process.env.REACT_APP_AWS_STORAGE_BUCKET_NAME;
    return `https://${bucket}.s3.amazonaws.com/media/user_manuals/`;
};

export { getBaseURL, getFileURL };