// React and third-party libraries
import { useState, useEffect } from 'react';

// Configurations
import { apiEndpoints, appEndpoints } from '../config/Endpoints';

const useDevices = () => {
    const [devices, setDevices] = useState([]);


    useEffect(() => {
        const fetchDevices = fetch(apiEndpoints.devices, {
            method: 'GET',
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Failed to fetch devices');
                if (response.status === 401) {
                    window.location = appEndpoints.login;
                }
            }
        });

        const fetchLegacySensors = fetch(apiEndpoints.legacySensors, {
            method: 'GET',
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Failed to fetch legacy sensors');
                if (response.status === 401) {
                    window.location = appEndpoints.login;
                }
            }
        });

        Promise.all([fetchDevices, fetchLegacySensors])
            .then(results => {
                const [devicesData, legacySensorsData] = results;
                const formattedDeviceData = formatDeviceData(devicesData.results);
                const formattedLegacySensorData = formatLegacySensorData(legacySensorsData.results);
                setDevices([...formattedDeviceData, ...formattedLegacySensorData]);
            })
            .catch(error => {
                console.error("Fetch error: ", error);
            });
    }, []);


    return devices;
};

const formatDeviceData = (data) => {
    const formattedData = data.map(device => ({
        id: device.id,
        device_name: device.device_name,
        device_type: device.device_type,
        key: `${device.id}:${device.device_type}`
    }));
    return formattedData;
}

const formatLegacySensorData = (data) => {
    const formattedData = data.map(sensor => ({
        id: sensor.id,
        device_name: sensor.sensor_name,
        device_type: 'LegacySensor',
        key: `${sensor.id}:LegacySensor`
    }));
    return formattedData;
}

export default useDevices;
