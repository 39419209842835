// Default values for each configuration field
const DEFAULT_FREE_COOLING_THRESHOLD = 50.0;
const DEFAULT_HEATING_DEADBAND = 0.8;
const DEFAULT_COOLING_DEADBAND = 0.8;
const DEFAULT_STAGE_2_HEATING_THRESHOLD = 35.0;
const DEFAULT_STAGE_2_COOLING_THRESHOLD = 72.0;
const DEFAULT_DAMPER_FULL_OPEN = 100.0;
const DEFAULT_DAMPER_SEMI_OPEN = 30.0;
const DEFAULT_LEARNING_RATE = 0.5;
const DEFAULT_CYCLE_DIVISIONS = 1.0;
const DEFAULT_FREE_COOLING_IS_ENABLED = false;
const DEFUALT_FAN_IS_ENABLED = true;
const DEFAULT_FLOW_IS_ENABLED = true;
const DEFAULT_SET_TEMP = 70.0;
const DEFAULT_HEAT_LIMIT = 60.0;
const DEFAULT_COOL_LIMIT = 80.0;
const DEFAULT_VOLTAGE_AT_SET_TEMP = 8.0;
const DEFAULT_VOLTAGE_AT_LIMIT = 10.0;
const DEFAULT_HEAT_OFFSET = 0;
const DEFAULT_COOL_OFFSET = 0;
const DEFAULT_ROOM_SETPOINT = 70;
const DEFAULT_DECISION_POINT = 55;
const DEFAULT_CYCLE_TIME = 5;
const DEFAULT_DOWNLINK_DELAY = 15;

// Enum-like object for flow types
const FLOWS = ['2 Stage ML HVAC',
    '1 Stage HVAC',
    '2 Stage HVAC',
    'Pump VFD Control',
]

const FLOW_TYPE_TO_NAME_MAPPING = {
    'full_cycle_hvac': '2 Stage HVAC',
    'full_cycle_single_phase_hvac': '1 Stage HVAC',
    'ml_hvac': '2 Stage ML HVAC',
    'pump_vfd_control': 'Pump VFD Control',
}

const FLOW_NAME_TO_TYPE_MAPPING = {
    '2 Stage HVAC': 'full_cycle_hvac',
    '1 Stage HVAC': 'full_cycle_single_phase_hvac',
    '2 Stage ML HVAC': 'ml_hvac',
    'Pump VFD Control': 'pump_vfd_control',
}

const FLOW_TYPE_TO_MODES_MAPPING = {
    'full_cycle_hvac': ['server_side', 'node_side'],
    'full_cycle_single_phase_hvac': ['server_side', 'node_side'],
    'ml_hvac': ['server_side'],
    'pump_vfd_control': ['server_side'],
}

const FLOW_PARAMETER_ORDER_DISPLAY_ORDER = {
    'ml_hvac': [
        "free_cooling_threshold",
        "heating_deadband",
        "cooling_deadband",
        "stage_2_heating_threshold",
        "stage_2_cooling_threshold",
        "damper_full_open",
        "damper_semi_open",
        "learning_rate",
        "cycle_divisions",
        "free_cooling_is_enabled",
        "fan_is_enabled",
        "heat_offset",
        "cool_offset",
        "room_setpoint",
        "decision_point",
        "cycle_time",
        "downlink_delay",
    ],
    'full_cycle_single_phase_hvac': [
        "flow_is_enabled",
        "free_cooling_is_enabled",
        "fan_is_enabled",
        "room_setpoint",
        "decision_point",
        "heating_deadband",
        "cooling_deadband",
        "free_cooling_threshold",
        "downlink_delay",
        "damper_full_open",
        "damper_semi_open",
        "cycle_time",
        "heat_offset",
        "cool_offset",
    ],
    'full_cycle_hvac': [
        "flow_is_enabled",
        "free_cooling_is_enabled",
        "fan_is_enabled",
        "room_setpoint",
        "decision_point",
        "heating_deadband",
        "cooling_deadband",
        "free_cooling_threshold",
        "stage_2_heating_threshold",
        "stage_2_cooling_threshold",
        "downlink_delay",
        "damper_full_open",
        "damper_semi_open",
        "cycle_time",
        "heat_offset",
        "cool_offset",
    ],
    'pump_vfd_control': [
        "set_temp",
        "heat_limit",
        "cool_limit",
        "voltage_at_set_temp",
        "voltage_at_limit",
    ]
}

// Configuration fields for each flow type
const FLOW_CONFIG_FIELDS = {
    'ml_hvac': {
        free_cooling_threshold: { type: 'float', value: DEFAULT_FREE_COOLING_THRESHOLD },
        heating_deadband: { type: 'float', value: DEFAULT_HEATING_DEADBAND },
        cooling_deadband: { type: 'float', value: DEFAULT_COOLING_DEADBAND },
        stage_2_heating_threshold: { type: 'float', value: DEFAULT_STAGE_2_HEATING_THRESHOLD },
        stage_2_cooling_threshold: { type: 'float', value: DEFAULT_STAGE_2_COOLING_THRESHOLD },
        damper_full_open: { type: 'float', value: DEFAULT_DAMPER_FULL_OPEN },
        damper_semi_open: { type: 'float', value: DEFAULT_DAMPER_SEMI_OPEN },
        learning_rate: { type: 'float', value: DEFAULT_LEARNING_RATE },
        cycle_divisions: { type: 'float', value: DEFAULT_CYCLE_DIVISIONS },
        free_cooling_is_enabled: { type: 'boolean', value: DEFAULT_FREE_COOLING_IS_ENABLED },
        fan_is_enabled: { type: 'boolean', value: DEFUALT_FAN_IS_ENABLED },
        heat_offset: { "type": "float", "value": DEFAULT_HEAT_OFFSET },
        cool_offset: { "type": "float", "value": DEFAULT_COOL_OFFSET },
        room_setpoint: { "type": "float", "value": DEFAULT_ROOM_SETPOINT },
        decision_point: { "type": "float", "value": DEFAULT_DECISION_POINT },
        cycle_time: { "type": "float", "value": DEFAULT_CYCLE_TIME },
        downlink_delay: { "type": "int", "value": DEFAULT_DOWNLINK_DELAY },
    },
    'full_cycle_single_phase_hvac': {
        free_cooling_threshold: { type: 'float', value: DEFAULT_FREE_COOLING_THRESHOLD },
        heating_deadband: { type: 'float', value: DEFAULT_HEATING_DEADBAND },
        cooling_deadband: { type: 'float', value: DEFAULT_COOLING_DEADBAND },
        damper_full_open: { type: 'float', value: DEFAULT_DAMPER_FULL_OPEN },
        damper_semi_open: { type: 'float', value: DEFAULT_DAMPER_SEMI_OPEN },
        free_cooling_is_enabled: { type: 'boolean', value: DEFAULT_FREE_COOLING_IS_ENABLED },
        fan_is_enabled: { type: 'boolean', value: DEFUALT_FAN_IS_ENABLED },
        flow_is_enabled: { type: 'boolean', value: DEFAULT_FLOW_IS_ENABLED },
        heat_offset: { "type": "float", "value": DEFAULT_HEAT_OFFSET },
        cool_offset: { "type": "float", "value": DEFAULT_COOL_OFFSET },
        room_setpoint: { "type": "float", "value": DEFAULT_ROOM_SETPOINT },
        decision_point: { "type": "float", "value": DEFAULT_DECISION_POINT },
        cycle_time: { "type": "float", "value": DEFAULT_CYCLE_TIME },
        downlink_delay: { "type": "int", "value": DEFAULT_DOWNLINK_DELAY },
    },
    'full_cycle_hvac': {
        free_cooling_threshold: { type: 'float', value: DEFAULT_FREE_COOLING_THRESHOLD },
        heating_deadband: { type: 'float', value: DEFAULT_HEATING_DEADBAND },
        cooling_deadband: { type: 'float', value: DEFAULT_COOLING_DEADBAND },
        stage_2_heating_threshold: { type: 'float', value: DEFAULT_STAGE_2_HEATING_THRESHOLD },
        stage_2_cooling_threshold: { type: 'float', value: DEFAULT_STAGE_2_COOLING_THRESHOLD },
        damper_full_open: { type: 'float', value: DEFAULT_DAMPER_FULL_OPEN },
        damper_semi_open: { type: 'float', value: DEFAULT_DAMPER_SEMI_OPEN },
        free_cooling_is_enabled: { type: 'boolean', value: DEFAULT_FREE_COOLING_IS_ENABLED },
        fan_is_enabled: { type: 'boolean', value: DEFUALT_FAN_IS_ENABLED },
        flow_is_enabled: { type: 'boolean', value: DEFAULT_FLOW_IS_ENABLED },
        heat_offset: { "type": "float", "value": DEFAULT_HEAT_OFFSET },
        cool_offset: { "type": "float", "value": DEFAULT_COOL_OFFSET },
        room_setpoint: { "type": "float", "value": DEFAULT_ROOM_SETPOINT },
        decision_point: { "type": "float", "value": DEFAULT_DECISION_POINT },
        cycle_time: { "type": "float", "value": DEFAULT_CYCLE_TIME },
        downlink_delay: { "type": "int", "value": DEFAULT_DOWNLINK_DELAY },
    },
    'pump_vfd_control': {
        set_temp: { type: 'float', value: DEFAULT_SET_TEMP },
        heat_limit: { type: 'float', value: DEFAULT_HEAT_LIMIT },
        cool_limit: { type: 'float', value: DEFAULT_COOL_LIMIT },
        voltage_at_set_temp: { type: 'float', value: DEFAULT_VOLTAGE_AT_SET_TEMP },
        voltage_at_limit: { type: 'float', value: DEFAULT_VOLTAGE_AT_LIMIT },
    },
};

// Mapping parameter keys to human-readable names
const FLOW_PARAMETER_NAMES = Object.freeze({
    free_cooling_threshold: 'Economizer Cooling Threshold',
    heating_deadband: 'Heating Deadband',
    cooling_deadband: 'Cooling Deadband',
    stage_2_heating_threshold: 'Stage 2 Heating Threshold',
    stage_2_cooling_threshold: 'Stage 2 Cooling Threshold',
    damper_full_open: 'Damper Full Open',
    damper_semi_open: 'Damper Semi Open',
    damper_closed: 'Damper Closed',
    learning_rate: 'Learning Rate',
    cycle_divisions: 'Cycle Divisions',
    free_cooling_is_enabled: 'Enable Economizer Cooling',
    fan_is_enabled: 'Enable Fan',
    set_temp: 'Set Temp',
    heat_limit: 'Heat Limit',
    cool_limit: 'Cool Limit',
    voltage_at_set_temp: 'Voltage at Set Temp',
    voltage_at_limit: 'Voltage at Limit',
    is_node_side_flow: 'Node Side Flow',
    flow_is_enabled: "Enable Flow",
    heat_offset: "Heat Offset",
    cool_offset: "Cool Offset",
    room_setpoint: "Room Setpoint",
    decision_point: "Decision Point",
    cycle_time: "Cycle Time",
    downlink_delay: "Downlink Delay",
});


export {
    FLOW_TYPE_TO_NAME_MAPPING,
    FLOW_NAME_TO_TYPE_MAPPING,
    FLOWS, FLOW_CONFIG_FIELDS,
    FLOW_PARAMETER_NAMES,
    FLOW_TYPE_TO_MODES_MAPPING,
    FLOW_PARAMETER_ORDER_DISPLAY_ORDER
}