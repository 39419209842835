import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#153403',
        },
        error: {
            main: '#ff1744',
        },
        warning: {
            main: '#ff9800',
        },
        info: {
            main: '#2196f3',
        },
        success: {
            main: '#4caf50',
        },
        background: {
            default: '#f2f3f8', // Primary background (Off White)
            bright: '#ffffff', // Secondary (White)
        },
        text: {
            dark: '#212529', // Primary Text (Darker Gray)
            green: '#153610', // Secondary Text/Accents (Lighter Gray)
            white: '#ffffff' // For use on dark green background
        },
    },
});

export default theme;