// React and third-party libraries
import React, { createContext, useState, useContext } from 'react';



const NotificationContext = createContext();


export const useNotification = () => {
    return useContext(NotificationContext);
};


export const NotificationProvider = ({ children }) => {
    const [message, setMessage] = useState(null);
    const [severity, setSeverity] = useState('success');

    const showMessage = (msg, type = 'success') => {
        setMessage(msg);
        setSeverity(type);

        // Auto-hide message after 6 seconds (6000ms)
        setTimeout(() => setMessage(null), 6000);
    };

    return (
        <NotificationContext.Provider value={{  message, severity, showMessage }}>
            {children}
        </NotificationContext.Provider>
    );
};
