// React and third-party libraries
import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    Stepper,
    Step,
    StepLabel,
    Button,
    Box,
    DialogActions,
    Typography,
    CircularProgress,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete'

//components
import MultiSelectDropdown from './MultiSelectDropdown';

// Styles
import './Wizard.css'


const Wizard = ({ open, steps, isSubmit, handleClose, handleFinish, error}) => {
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => setActiveStep((prev) => prev + 1);
    const handleBack = () => setActiveStep((prev) => prev - 1);
    const handleReset = () => setActiveStep(0);

    const getStepContent = (stepIndex) => {
        if (steps[stepIndex] && typeof steps[stepIndex].content === 'function') {
            return steps[stepIndex].content();
        }
        return 'Unknown step';
    };

    const handleWizardClose = () => {
        handleReset();
        handleClose();
    };

    const handleWizardFinish = () => {
        handleFinish();
    };

    useEffect(() => {
        if (!open) {
            handleReset();
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                    return;
                }
                handleClose();
            }}
            fullWidth
            maxWidth="lg"
        >
            <DialogContent>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepLabel>{step.label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box className="stepContent" sx={{ mt: 2, mb: 1 }}>
                    {getStepContent(activeStep)}
                </Box>
                <Box sx={{ mt: 2, mb: 1, ml: 6 }}>
                    <Typography color="error">
                        {error && Object.entries(error).map(([field, value]) => {
                            if (field === 'detail' && typeof value === 'string') {
                                return (
                                    <div key={field}>
                                        {value}
                                    </div>
                                );
                            } else if (Array.isArray(value)) {
                                return (
                                    <div key={field}>
                                        <strong>{field}:</strong> {value.join(', ')}
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </Typography>
                </Box>
                <DialogActions>
                    <Button onClick={handleWizardClose}>
                        Cancel
                    </Button>
                    {activeStep > 0 && (
                        <Button onClick={handleBack}>
                            Back
                        </Button>
                    )}
                    {activeStep < steps.length - 1 && (
                        <Button variant="contained" color="primary" onClick={handleNext}>
                            Next
                        </Button>
                    )}
                    {activeStep === steps.length - 1 && (
                        <Button variant="contained" color="primary" onClick={handleWizardFinish} disabled={isSubmit}>
                            Finish
                        </Button>
                    )}
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default Wizard;
