import { useState, useEffect } from 'react';
import axios from 'axios';
import { apiEndpoints } from '../config/Endpoints';

const useFlows = () => {
    const [flows, setFlows] = useState([]);

    useEffect(() => {
        axios
            .get(apiEndpoints.flows, { withCredentials: true })
            .then((res) => {
                const flowData = formatFlowData(res.data.data);
                setFlows(flowData);
            })
            .catch((error) => {
                console.error('Error fetching flow data:', error);
            });
    }, []);

    return flows;
}

const formatFlowData = (data) => {
    return data.map((flow) => ({
        ...flow,
        flow_type: flow.flow_type,
    }));
};

export default useFlows;
