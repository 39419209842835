// React and third-party libraries
import React, { useState, useEffect, Fragment } from 'react';
import {Grid, Button, FormControl} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import Chart from '../widgets/chart_widgets/Chart';
import DeviceSelector from '../general/DeviceSelector';
import ParameterSelector from '../general/ParameterSelector';
import IntervalSelector from '../general/IntervalSelector';
import RangeSelector from '../general/RangeSelector';

// Configurations
import {apiEndpoints, appEndpoints} from '../../config/Endpoints';

// Data hooks
import useDevices from '../../hooks/Devices';
import useDeviceParameters from '../../hooks/Parameters';
import useSaveRangeSetting from '../../hooks/ReportSetting';


// Styles
import './Reporting.css'


const Reporting = () => {
    const devices = useDevices();
    const [device, setDevice] = useState({ 'key': '', 'id': 0, 'name': 'No device selected', 'type': ''});
    const parameters = useDeviceParameters(device);
    const [parameter, setParameter] = useState({ 'id': '', 'name': 'No parameter selected' });
    const [reportingInterval, setReportingInterval] = useState({'name': 'Daily', 'hrs': 24});
    const [reportingIntervals] = useState([
        {'name': 'Daily', 'hrs': 24},
        {'name': 'Weekly', 'hrs': 24 * 7},
        {'name': 'Monthly', 'hrs': 24 * 30},
    ]);

    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(10);
    const [reportSetting, setReportSetting] = useState({});
    const [rangeUpdate, setRangeUpdate] = useState(false);
    const [showRange, setShowRange] = useState(false);
    const {isReportRangeLoading, saveRange} = useSaveRangeSetting();

    const handleDeviceChange = (value) => {
        const selectedDevice = devices.find(device => device.key === value);

        setDevice({
            'key': selectedDevice.key,
            'id': selectedDevice.id,
            'name': selectedDevice.device_name,
            'type': selectedDevice.device_type
        });
        resetParameter();
    };

    const handleParameterChange = (value) => {
        const selectedParameter = parameters.find(parameter => parameter.id === value);

        setParameter({
            'id': value,
            'name': selectedParameter.name
        });
    };

    const handleReportingIntervalChange = (value) => {
        const selectedReportingInterval = reportingIntervals.find(interval => interval.hrs === value);

        setReportingInterval(selectedReportingInterval);
    };

    const resetParameter = () => {
        setParameter({ 'id': '', 'name': 'No parameter selected' });
    }

    const handleMinValue = (value) => {
        setMinValue(value);
    };

    const handleMaxValue = (value) => {
        setMaxValue(value);
    };

    const handleSaveRange = () => {
        setRangeUpdate(true);
        saveRange(device.id, device.type, parameter.id, reportSetting);
    };

    useEffect(() => {
        setRangeUpdate(false);

        if (device.type === 'LegacySensor') {
            setReportSetting(prevSettings => ({
                ...prevSettings,
                [`${parameter.id}_range_min`]: parseInt(minValue),
                [`${parameter.id}_range_max`]: parseInt(maxValue)
            }));
        } else {
            setReportSetting(prevSettings => ({
                ...prevSettings,
                [parameter.id]: {
                    ...prevSettings[parameter.id],
                    min: parseInt(minValue),
                    max: parseInt(maxValue)
                }
            }));
        }

    }, [minValue, maxValue]);

    useEffect(() => {
        if (device.type === 'LegacySensor' && `${parameter.id}_range_min` in reportSetting) {
            setMinValue(reportSetting[`${parameter.id}_range_min`]);
            setMaxValue(reportSetting[`${parameter.id}_range_max`]);
        } else if (device.type === 'Sensor' && parameter.id in reportSetting) {
            setMinValue(reportSetting[parameter.id].min);
            setMaxValue(reportSetting[parameter.id].max);
        }
    }, [reportSetting, parameter]);

    return (
        <Grid container className="grid-container" spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <DeviceSelector
                    devices={devices}
                    selectedDeviceKey={device.key}
                    onDeviceChange={handleDeviceChange}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <ParameterSelector
                    parameters={parameters}
                    selectedParameterId={parameter.id}
                    onParameterChange={handleParameterChange}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <IntervalSelector
                    label={"Reporting Interval"}
                    Intervals={reportingIntervals}
                    selectedIntervalHrs={reportingInterval.hrs}
                    onIntervalChange={handleReportingIntervalChange}
                />
            </Grid>
            {
                showRange ?
                    <Fragment>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <RangeSelector
                                label="Range Min"
                                value={minValue}
                                onRangeChange={handleMinValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <RangeSelector
                                label="Range Max"
                                value={maxValue}
                                onRangeChange={handleMaxValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={1} xl={1}>
                            <Button
                                variant="contained"
                                className="save-range"
                                onClick={handleSaveRange}
                                fullWidth
                            >
                                {isReportRangeLoading ? <CircularProgress size={30} /> : "Save Range"}
                            </Button>
                        </Grid>
                    </Fragment>: null
            }
            <Grid item className="reporting-container" xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="reporting-container">
                    <Chart
                        device={device}
                        parameter={parameter}
                        interval={reportingInterval}
                        reportSetting={reportSetting}
                        setReportSetting={setReportSetting}
                        setShowRange={setShowRange}
                        rangeUpdate={rangeUpdate}
                    />
                </div>
            </Grid>

        </Grid>
    );
};

export default Reporting;
