import React, { useState, useCallback } from 'react';
import {
    Box,
    TextField,
    Grid,
} from '@mui/material';

import DeviceSelector from 'components/general/DeviceSelector';
import FlowSelector from 'components/general/FlowSelector';
import FlowModeSelector from 'components/general/FlowModeSelector';
import NumericalParameterSelector from 'components/general/NumericalParameterSelector';

import DataTable from 'components/general/DataTable';

import Wizard from 'components/general/Wizard';

import useDevices from 'hooks/Devices';
import useFlows from 'hooks/Flows';

import { apiEndpoints } from 'config/Endpoints';

import 'components/routes/FlowConfiguration.css';

import { FLOW_CONFIG_FIELDS, FLOW_PARAMETER_NAMES, FLOW_PARAMETER_ORDER_DISPLAY_ORDER } from 'components/utils/Flows.js';

const FlowConfiguration = () => {
    // Hooks to fetch device and flow info
    const devices = useDevices();
    const flows = useFlows();

    // Default values for flow on page load
    const defaultFlowID = 0;
    const defaultDevice = { key: '0:Node B', id: 0, device_name: 'No device selected', device_type: 'Node B' }
    const defaultFlowType = '';
    const defaultFlowMode = '';
    const defaultFlowStatus = 'create_unack'
    const defaultFlowParameters = { "data": {} };

    // States for flow parameters
    const [flowID, setFlowID] = useState(defaultFlowID)
    const [device, setDevice] = useState(defaultDevice);
    const [flowType, setFlowType] = useState(defaultFlowType);
    const [flowMode, setFlowMode] = useState(defaultFlowMode);
    const [flowStatus, setFlowStatus] = useState(defaultFlowStatus);
    const [flowParameters, setFlowParameters] = useState(defaultFlowParameters);

    // State for managing wizard steps
    const [wizardOpen, setWizardOpen] = useState(false);
    const [error, setError] = useState({});
    const [isViewMode, setIsViewMode] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    // States for DataTable use
    const [isSubmit, setIsSubmit] = useState(false);
    const [newData, setNewData] = useState(null);

    const endpoint = apiEndpoints.flows
    const columns = [
        {
            name: 'flow_type',
            label: 'Flow Type',
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: 'device_name',
            label: 'Device',
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        {
            name: 'flow_mode',
            label: 'Flow Mode',
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        }
    ];

    const sortParameters = (a, b) => {
        const order = FLOW_PARAMETER_ORDER_DISPLAY_ORDER[flowType] || [];
        return order.indexOf(a[0]) - order.indexOf(b[0]);
    };

    const resetWizard = () => {
        setDevice(defaultDevice);
        setFlowType(defaultFlowType);
        setFlowParameters(defaultFlowParameters);
        setFlowMode(defaultFlowMode);
        setFlowStatus(defaultFlowStatus)
        setError();
    }

    const populateFields = (data) => {
        const selectedDevice = devices.find(device => device.id === parseInt(data.device, 10));
        setFlowID(data.id);
        setDevice(selectedDevice);
        setFlowType(data.flow_type)
        setFlowMode(data.flow_mode)
        setFlowStatus(data.status)
        setFlowParameters(data.data)
    }

    const handleOpen = () => {
        setIsSubmit(false);
        setIsViewMode(false);
        setIsEditMode(false);
        resetWizard();
        setWizardOpen(true);
    };

    const handleClose = () => {
        resetWizard();
        setWizardOpen(false);
    };

    const handleFinish = () => {
        if (isViewMode) {
            setWizardOpen(false);
            return;
        }
        if (isEditMode) {
            const data = {
                flow_id: flowID,
                device: device.id,
                flow_type: flowType,
                flow_mode: flowMode,
                data: flowParameters,
            };
            setNewData(data);
        }
        else {
            const data = {
                device: device.id,
                flow_type: flowType,
                flow_mode: flowMode,
                data: flowParameters,
            };
            setNewData(data);
        }
        setIsSubmit(true);
    };

    const handleViewOpen = (data) => {
        setIsSubmit(false)
        populateFields(data);
        setIsEditMode(false);
        setIsViewMode(true);
        setWizardOpen(true);
    };

    const handleEditOpen = (data) => {
        setIsSubmit(false)
        setIsEditMode(true);
        setIsViewMode(false);
        populateFields(data)
        setWizardOpen(true);
    };

    const handleFlowParameterChange = useCallback((key, value) => {
        setFlowParameters((prevParameters) => ({
            ...prevParameters,
            [key]: {
                ...prevParameters[key],
                value,
            },
        }));
    }, []);

    const setFlowParametersOnFlowSelect = useCallback((selectedFlowType) => {
        const matchingFlow = flows.find((flow) =>
            flow.flow_type === selectedFlowType && flow.device === device.id
        );
        const newFlowParameters = matchingFlow
            ? matchingFlow.data
            : FLOW_CONFIG_FIELDS[selectedFlowType];
        setFlowParameters(newFlowParameters);
    }, [flows, device]);

    const handleFlowTypeChange = useCallback((newFlowType) => {
        setFlowType(newFlowType);
        setFlowParametersOnFlowSelect(newFlowType);
    }, [setFlowParametersOnFlowSelect]);

    const setFlowTypeOnDeviceSelect = useCallback((selectedDevice) => {
        const matchingFlow = flows.find(flow => flow.device === selectedDevice.id);
        const newFlowType = matchingFlow ? matchingFlow.flow_type : defaultFlowType;
        handleFlowTypeChange(newFlowType);
    }, [flows, handleFlowTypeChange]);

    const handleDeviceChange = useCallback((value) => {
        const selectedDevice = devices.find((device) => device.key === value);
        if (selectedDevice) {
            setDevice({
                key: selectedDevice.key,
                id: selectedDevice.id,
                name: selectedDevice.device_name,
                type: selectedDevice.device_type,
            });
            setFlowTypeOnDeviceSelect(selectedDevice);
        }
    }, [devices, setFlowTypeOnDeviceSelect]);

    const steps = [
        {
            label: 'Basic Information',
            content: () => (
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Box marginBottom={2}>
                            <DeviceSelector
                                devices={devices}
                                selectedDeviceKey={device.key}
                                onDeviceChange={handleDeviceChange}
                                disabled={isEditMode || isViewMode}
                            />
                        </Box>

                        <Box marginBottom={2}>
                            <FlowSelector
                                selectedFlowKey={flowType}
                                onFlowChange={handleFlowTypeChange}
                                disabled={isEditMode || isViewMode}

                            />
                        </Box>
                        <Box marginBottom={2}>
                            <FlowModeSelector
                                selectedFlowKey={flowType}
                                selectedFlowModeKey={flowMode}
                                onFlowModeChange={setFlowMode}
                                disabled={isEditMode || isViewMode}
                            />
                        </Box>
                        <Box marginBottom={2}>
                            <TextField
                                label="Flow Status"
                                fullWidth
                                variant="outlined"
                                value={flowStatus}
                                required
                                disabled={true}
                            />
                        </Box>
                        {flowType !== defaultFlowType && (
                            Object.entries(flowParameters)
                                .filter(([parameter]) => parameter !== 'status' && parameter !== 'patio_door')
                                .sort(sortParameters)
                                .map(([parameter, { type, value }]) => (
                                    <Box marginBottom={2} key={parameter}>
                                        <NumericalParameterSelector
                                            initialDisplayValue={value}
                                            parameterDisplayName={FLOW_PARAMETER_NAMES[parameter]}
                                            parameterID={parameter}
                                            onParameterChange={(value, parameterID) => handleFlowParameterChange(parameterID, value)}
                                            parameterType={type}
                                        />
                                    </Box>
                                ))
                        )}
                    </Grid>
                </Grid>
            ),
        },
    ]

    return (
        <Grid container className="grid-container" spacing={2}>
            <Grid item className="tenant-management-container" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Wizard
                    open={wizardOpen}
                    steps={steps}
                    error={error}
                    isSubmit={isSubmit}
                    handleClose={() => handleClose()}
                    handleFinish={() => handleFinish()}
                />
                <DataTable
                    name="Flow"
                    title="Logic Flows"
                    columns={columns}
                    endpoint={endpoint}
                    newData={newData}
                    isSubmit={isSubmit}
                    setIsSubmit={setIsSubmit}
                    handleOpen={() => handleOpen()}
                    handleClose={() => handleClose()}
                    handleViewOpen={(data) => handleViewOpen(data)}
                    handleEditOpen={(data) => handleEditOpen(data)}
                    setError={setError}
                />
            </Grid>
        </Grid>
    );
};

export default FlowConfiguration;