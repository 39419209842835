import { useState } from 'react';
import { appEndpoints } from '../config/Endpoints';

import Cookies from 'js-cookie';


const useDeleteItem = (endpoint, setRefetchIndex) => {
    const deleteItem = async (id) => {
        try {
            const csrfToken = Cookies.get('csrftoken');
            const response = await fetch(`${endpoint}${id}/`, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
            });
            if (response.ok) {
                setRefetchIndex(prevIndex => prevIndex + 1);
            } else {
                console.error("Failed to delete rules");
            }
        } catch (error) {
            console.error("Error deleting item: ", error);
        }
    };

    return { deleteItem };
};

export default useDeleteItem;
