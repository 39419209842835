// React and third-party libraries
import React from 'react';
import { Alert, Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const Notification = ({ message, severity = 'success', onClose }) => {
    return (
        <Snackbar
            open={Boolean(message)}
            autoHideDuration={6000}
            onClose={onClose}
            message={message}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                onClose={onClose}
                severity={severity}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                }
            >
                {message}
            </Alert>
        </Snackbar>
    );
};

export default Notification;
