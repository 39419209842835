import React from 'react';
import { FormControl, MenuItem, TextField } from '@mui/material';

const IntervalSelector = ({ label, Intervals, selectedIntervalHrs, onIntervalChange }) => {
    return (
        <FormControl fullWidth>
            <TextField
                select
                label={label}
                value={selectedIntervalHrs}
                onChange={e => onIntervalChange(e.target.value)}
                required
            >
                {Intervals.map(interval => (
                    <MenuItem key={interval.hrs} value={interval.hrs}>
                        {interval.name}
                    </MenuItem>
                ))}
            </TextField>
        </FormControl>
    );
};

export default IntervalSelector;
