// React and third-party libraries
import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  TextField,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
} from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete'

// Components
import DataTable from '../general/DataTable';
import Wizard from '../general/Wizard';

// Configurations
import {apiEndpoints, appEndpoints} from '../../config/Endpoints';
import {timezones} from '../../config/Timezone';

//styles
import './TenantManagement.css'


const TenantManagement = () => {
    const [error, setError] = useState({});
    const [isWizardOpen, setWizardOpen] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [name, setName] = useState('');
    const [schemaName, setSchemaName] = useState('');
    const [isMultigatewayTenant, setIsMultigatewayTenant] = useState('');
    const [appEui, setAppEui] = useState('');
    const [appKey, setAppKey] = useState('');
    const [gateways, setGateways] = useState(['']);
    const [latitude, setLatitude] = useState(43.3255000);
    const [longitude, setLongitude] = useState(-79.7990000);
    const [timezone, setTimezone] = useState('America/New_York');
    const [homepage, setHomepage] = useState('/app/v3/dashboard/');
    const [domain, setDomain] = useState('');

    const [newData, setNewData] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const endpoint = apiEndpoints.tenantManagementEndpoint;
    const columns = [
        {
            name: "name",
            label: "Name",
        },
        {
            name: "schema_name",
            label: "Schema Name",
        },
        {
            name: "domain",
            label: "Domain",
        }
    ];

    const handleGatewayChange = (index, value) => {
        const updateGateways = gateways.map((gateway, i) => 
            i === index ? value : gateway
        );
        setGateways(updateGateways);
    };

    const removeGateway = (index) => {
        setGateways(gateways.filter((_, i) => i !== index));
    };

    const addGateway = () => {
        setGateways(gateways.concat(''));
    };

    const resetWizard = () => {
        setName('');
        setSchemaName('');
        setIsMultigatewayTenant(false);
        setLatitude(43.3255000);
        setLongitude(-79.7990000);
        setTimezone('America/New_York');
        setHomepage('/app/v3/dashboard/');
        setDomain('');
        setAppEui('');
        setAppKey('');
        setGateways(['']);
        setError({});
    };

    const populateFields = (data) => {
        setName(data.name);
        setSchemaName(data.schema_name);
        setIsMultigatewayTenant(data.is_multigateway_tenant);
        setLatitude(data.latitude);
        setLongitude(data.longitude);
        setTimezone(data.timezone);
        setHomepage(data.home_page);
        setDomain(data.domain);
        setAppEui(data.lora_server_config["app_eui"])
        setAppKey(data.lora_server_config["app_key"])
        setGateways(data.lora_server_config["gateway_ids"])
    };

    const handleOpen = () => {
        setIsSubmit(false);
        setIsViewMode(false);
        setIsEditMode(false);
        resetWizard();
        setWizardOpen(true);
    };

    const handleClose = () => {
        resetWizard();
        setWizardOpen(false);
    };

    const handleFinish = () => {
        if (isViewMode){
            setWizardOpen(false);
            return;
        }
        const data = {
            name: name,
            schema_name: schemaName,
            is_multigateway_tenant: isMultigatewayTenant,
            lora_server_config: {
                app_eui: appEui,
                app_key: appKey,
                gateway_ids: gateways
            },
            timezone,
            latitude,
            longitude,
            home_page: homepage,
            domain,
        };
        setNewData(data);
        setIsSubmit(true);
    };

    const handleViewOpen = (data) => {
        setIsSubmit(false);
        populateFields(data);
        setIsEditMode(true);
        setIsViewMode(true);
        setWizardOpen(true);
    };

    const handleEditOpen = (data) => {
        setIsSubmit(false);
        populateFields(data);
        setIsViewMode(false);
        setIsEditMode(true);
        setWizardOpen(true);
    };

    const steps = [
      {
        label: 'Basic Information',
        content: () => (
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="h6" sx={{ mb: 10 }}>
                        Enter Tenant Basic Information
                    </Typography>
                    <Box marginBottom={2}>
                        <TextField
                            label="Tenant Name"
                            fullWidth
                            variant="outlined"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            disabled={isViewMode}
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <TextField
                            label="Schema Name"
                            fullWidth
                            variant="outlined"
                            value={schemaName}
                            onChange={(e) => setSchemaName(e.target.value)}
                            required
                            disabled={isViewMode || isEditMode}
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <TextField
                            label="Domain"
                            fullWidth
                            variant="outlined"
                            value={domain}
                            onChange={(e) => setDomain(e.target.value)}
                            required
                            disabled={isViewMode}
                        />
                    </Box>
                </Grid>
            </Grid>
        )
      },
      {
        label: 'LoRa Server',
        content: () => (
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="h6" sx={{ mb: 10 }}>
                        Enter LoRa Server Information
                    </Typography>
                    <Box marginBottom={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isMultigatewayTenant}
                                    onChange={(e) => setIsMultigatewayTenant(e.target.checked)}
                                />
                            }
                            label="Multi-gateway tenant"
                            disabled={isViewMode}
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <TextField
                            label="App EUI"
                            fullWidth
                            variant="outlined"
                            value={appEui}
                            onChange={(e) => setAppEui(e.target.value)}
                            required
                            disabled={isViewMode}
                        />
                    </Box>
                    {isMultigatewayTenant && (
                        <>
                            <Box marginBottom={2}>
                                <TextField
                                    label="App Key"
                                    fullWidth
                                    variant="outlined"
                                    value={appKey}
                                    onChange={(e) => setAppKey(e.target.value)}
                                    required
                                    disabled={isViewMode}
                                />
                            </Box>
                            {gateways.map((gateway, index) => (
                                <Box key={index} sx={{ mb: 2 }}>
                                    <TextField
                                        label="Gateway EUI"
                                        fullWidth
                                        variant="outlined"
                                        value={gateway}
                                        onChange={(e) => handleGatewayChange(index, e.target.value)}
                                        required
                                        disabled={isViewMode}
                                    />
                                    <div key={index} className="field-sets-action">
                                        {gateways.length > 1 && (
                                            <Button
                                                variant="outlined"
                                                onClick={() => removeGateway(index)}
                                                color="error"
                                                startIcon={<DeleteIcon />}
                                                style={{ marginRight: '10px' }}
                                                disabled={isViewMode}
                                            >
                                                Remove
                                            </Button>
                                        )}

                                        {index === gateways.length - 1 && (
                                            <Button
                                                variant="outlined"
                                                onClick={addGateway}
                                                color="primary"
                                                startIcon={<AddIcon />}
                                                disabled={isViewMode}
                                            >
                                                Add
                                            </Button>
                                        )}
                                    </div>
                                </Box>
                            ))}
                        </>
                    )}
                </Grid>
            </Grid>
        )
      },
      {
        label: 'Location and Timezone',
        content: () => (
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="h6" sx={{ mb: 10 }}>
                        Configure Location and Timezone
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            fullWidth
                            label="Latitude"
                            variant="outlined"
                            type="number"
                            value={latitude}
                            onChange={(e) => setLatitude(e.target.value)}
                            required
                            disabled={isViewMode}
                        />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            fullWidth
                            label="Longitude"
                            variant="outlined"
                            type="number"
                            value={longitude}
                            onChange={(e) => setLongitude(e.target.value)}
                            required
                            disabled={isViewMode}
                        />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Autocomplete
                            freeSolo
                            options={timezones}
                            value={timezone}
                            onChange={(event, newValue) => setTimezone(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Timezone"
                                    variant="outlined"
                                    required
                                />
                            )}
                            clearOnEscape
                            disabled={isViewMode}
                        />
                    </Box>
                </Grid>
            </Grid>
        )
      },
      {
        label: 'Home Page',
        content: () => (
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="h6" sx={{ mb: 10 }}>
                        Setup Homepage
                    </Typography>
                    <Box marginBottom={2}>
                        <TextField
                            label="Home Page"
                            fullWidth
                            variant="outlined"
                            value={homepage}
                            onChange={(e) => setHomepage(e.target.value)}
                            required
                            disabled={isViewMode}
                        />
                    </Box>
                </Grid>
            </Grid>
        )
      },
    ];

    return (
        <Grid container className="grid-container" spacing={2}>
            <Grid item className="tenant-management-container" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Wizard
                    open={isWizardOpen}
                    steps={steps}
                    error={error}
                    isSubmit={isSubmit}
                    handleClose={() => handleClose()}
                    handleFinish ={() => handleFinish()}
                />
                <DataTable
                    name="Tenant"
                    title="Tenant Management"
                    columns={columns}
                    endpoint={endpoint}
                    newData={newData}
                    isSubmit={isSubmit}
                    setIsSubmit={setIsSubmit}
                    handleOpen={() => handleOpen()}
                    handleClose={() => handleClose()}
                    handleViewOpen ={(data) => handleViewOpen(data)}
                    handleEditOpen ={(data) => handleEditOpen(data)}
                    setError={setError}
                />
            </Grid>
        </Grid>
    );
};

export default TenantManagement;
