import React, { useState, useEffect, useCallback } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

import Cookies from 'js-cookie';

import $ from 'jquery';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';

import 'App.css'
import './Dashboard.css'

import {appEndpoints} from '../../config/Endpoints';

import DimmerDevice from '../widgets/device_widgets/DimmerDevice';
import NodeBDevice from '../widgets/device_widgets/NodeBDevice';
import SwitchDevice from '../widgets/device_widgets/SwitchDevice'
import ThermostatDevice from '../widgets/device_widgets/ThermostatDevice'

import SensorChart from '../widgets/chart_widgets/SensorChart';
import DeviceChart from '../widgets/chart_widgets/DeviceChart';

import AlertsTable from '../widgets/table_widgets/AlertsTable'

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = () => {
    const [layout, setLayout] = useState([]);  // Current layout state
    const [initialLayout, setInitialLayout] = useState([]); // Layout before editing
    const [isEditMode, setIsEditMode] = useState(false); // Edit mode state

    // Get tenant, use to create API endpoint
    const tenant = window.location.hostname.split(".")[0]
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const baseUrl = apiUrl.replace("*", tenant)

    // Retrieve 'username' from cookies
    const username = Cookies.get('username');
    const isAdmin = username === 'testuser_level3';

    // Transforms widget data to fomrat required by  ResponsiveGridLayout
    const transformToLayout = useCallback((widgets) => {
        return widgets.map((widget) => ({
            i: String(widget.id),
            widget_type: widget.widget_type,
            x: widget.x_position,
            y: widget.y_position,
            w: widget.width,
            h: widget.height,
            data_source: widget.data_source,
            static: !isEditMode
        }));
    }, [isEditMode]);

    const fetchWidgets = useCallback(async () => {
        try {
            $.ajax({
                url: `${baseUrl}/api/v2/dashboard_widget/`,
                method: 'GET',
                // No Authorization header needed, as cookies are automatically sent
                xhrFields: {
                    withCredentials: true // Ensure cookies are sent with requests across different domains
                },
                success: function (response) {
                    const transformedLayout = transformToLayout(response);
                    setLayout(transformedLayout);
                    setInitialLayout(transformedLayout);
                },
                error: function (error) {
                    if (error.status === 401) {
                        window.location = appEndpoints.login;
                    }
                }
            });
        } catch (error) {
            if (error.status) {
                console.error('HTTP Status:', error.status);
            }
            if (error.responseText) {
                console.error('Response Text:', error.responseText);
            }
        }
    }, [transformToLayout, baseUrl]);

    useEffect(() => {
        fetchWidgets(); // Fetch and set initial layout on component mount
    }, [fetchWidgets]);

    // onLayoutChange intakes layout variable provided by the ResponsiveGrid component.
    // This does not include the data_source info included in widgets, needs to be re added manually.
    const onLayoutChange = (newLayout) => {
        if (isEditMode) {
            // Map through newLayout and merge widget data
            const updatedLayout = newLayout.map(layoutItem => {
                // Find the corresponding item in the current layout
                const currentLayoutItem = layout.find(item => item.i === layoutItem.i);

                // Merge the current layout item with the new layout item
                return {
                    ...layoutItem,
                    data_source: currentLayoutItem ? currentLayoutItem.data_source : null,
                    widget_type: currentLayoutItem ? currentLayoutItem.widget_type : null
                };
            });
            setLayout(updatedLayout);
        }
    };

    // Edit button pushed => Turn on edit mode, save initial layout
    const toggleEditMode = () => {
        setIsEditMode(!isEditMode);
        if (!isEditMode) {
            setInitialLayout(layout); // Save current layout before editing
        }
    };

    // Cancel button pushed => Revert changes and turn off edit mode
    const cancelChanges = () => {
        setLayout(initialLayout);
        setIsEditMode(false);
    };

    // Save layout to backend
    const saveLayout = async () => {
        // Transform the layout data to match expected format
        const transformedLayout = layout.map(item => ({
            id: parseInt(item.i),
            widget_type: item.widget_type,
            x_position: item.x,
            y_position: item.y,
            width: item.w,
            height: item.h,
            data_source: item.data_source
        }));

        try {
            const csrfToken = Cookies.get('csrftoken');
            $.ajax({
                url: `${baseUrl}/api/v2/dashboard_widget/`,
                method: 'PUT',
                // No Authorization header needed, as cookies are automatically sent
                xhrFields: {
                    withCredentials: true
                },
                headers: {
                    'X-CSRFToken': csrfToken,
                },
                contentType: 'application/json',
                // Send the transformed layout
                data: JSON.stringify({ layout: transformedLayout }),
                success: function () {
                    console.log('Layout updated successfully:');
                    // Exit edit mode on successful save
                    setIsEditMode(false);
                },
                error: function (xhr) {
                    console.error('Error updating layout:', xhr.responseText);
                    // Allow user to try saving again
                    setIsEditMode(true);
                }
            });
        } catch (error) {
            console.error('Error obtaining access token:', error);
        }
    };

    // Function to map device types to corresponding components
    const getWidgetComponent = (widget) => {
        if (!widget.data_source) {
            return <div>Missing Data Source</div>;
        }

        // Sensor reporting component
        else if (widget.data_source.device_type === 'sensor' && widget.widget_type === 'chart') {
            return <SensorChart chart_id={widget.i} sensor_id={widget.data_source.id} sensor_name={widget.data_source.sensor_name} reporting_period={widget.data_source.reporting_period} data_type={widget.data_source.data_type} />;
        }

        // Device reporting component
        else if (widget.data_source.device_type !== 'sensor' && widget.widget_type === 'chart') {
            return <DeviceChart chart_id={widget.i} device_id={widget.data_source.id} device_name={widget.data_source.device_name} device_type={widget.data_source.device_type} reporting_period={widget.data_source.reporting_period} stack={widget.data_source.stack} board_type={widget.data_source.board_type} parameter={widget.data_source.parameter} />
        }

        // Dimmer live data component
        else if (widget.data_source.device_type === 'dimmer' && widget.widget_type === 'device') {
            return <DimmerDevice device_id={widget.data_source.id} device_name={widget.data_source.device_name} />;
        }

        // Node B device live data component
        else if (widget.data_source.device_type === 'Node B' && widget.widget_type === 'device') {
            return <NodeBDevice device_id={widget.data_source.id} device_name={widget.data_source.device_name} hex_address={widget.data_source.hex_address} board_type={widget.data_source.board_type} />;
        }

        // Switch live data component
        else if (widget.data_source.device_type === 'Switch' && widget.widget_type === 'device') {
            return <SwitchDevice device_id={widget.data_source.id} device_name={widget.data_source.device_name} />;
        }

        else if (widget.data_source.device_type === 'Thermostat' && widget.widget_type === 'device') {
            return <ThermostatDevice device_id={widget.data_source.id} device_name={widget.data_source.device_name} />
        }

        else if (widget.widget_type === 'table') {
            return <AlertsTable alert_types={widget.data_source.alert_types} />
        }

        else {
            return <div>Unknown Device Type</div>;
        }
    };

    return (
        <div className='dashboard-container'>
            <div className='grid-container'>
                <ResponsiveGridLayout
                    className="layout"
                    compactType={'vertical'}
                    layouts={{ lg: layout }}
                    onLayoutChange={onLayoutChange}
                    breakpoints={{ lg: 1200 }}
                    cols={{ lg: 96 }}
                    rowHeight={10}
                    width={1200}
                    margin={[8, 8]}
                    isDraggable={isEditMode}
                    isResizable={isEditMode}
                    resizeHandles={['se']}
                >
                    {layout.map(widget => (
                        <div key={widget.i}>
                            {getWidgetComponent(widget)}
                        </div>
                    ))}

                </ResponsiveGridLayout>
            </div>
            <div className='action-tab'>
                {isAdmin && (
                    <Fab color="primary" onClick={isEditMode ? saveLayout : toggleEditMode}>
                        {isEditMode ? <SaveIcon /> : <EditIcon />}
                    </Fab>
                )}
                {isEditMode && (
                    <Fab color="warning" onClick={cancelChanges} style={{ marginLeft: 16 }}>
                        <CancelIcon />
                    </Fab>
                )}
            </div>
        </div>
    );
};

export default Dashboard;
